import { useAuthStore } from '~/store/auth';

export default defineNuxtRouteMiddleware(async(to) => {
    const { loggedIn } = useUserSession();

    if (!loggedIn.value) {
        useAuthStore().setInitialPageCookie(to.path);

        return navigateTo('/auth/login', {
            external: true
        });
    }
});
 